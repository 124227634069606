.pagination {
  @extend %flex-center-ja;
  flex-wrap: wrap;
  gap: 8px;

  padding: $padding-small;
  z-index: 1;
}

.paginationBtn {
  @extend .btn;
  width: 40px;
  height: 35px;
}