#benefits {  
  background-color: var(--color__accent);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.benefits__title {
  color: white;
  margin-bottom: 24px;
}

.benefits__list {
  @extend %cardSet;
  gap: 24px;

  @include breakpoint-min($medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint-min($large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.benefits__item {
  @extend %cardSet__item;
  @extend %flex-center-a;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  background-color: white;
  @extend %border-radius;

  @include breakpoint-min-max($medium, $mediumMax) {
    &:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(1),
    &:nth-child(3) {
      order: -1;
    }
  }
  @include breakpoint-min($large) {
    &:nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}

.benefits__img {
  width: 400px;
  height: 200px;

  object-fit: cover;
  @extend %border-radius;

  @include breakpoint-max($smallMax) {
    width: 100%;
  }
}

.benefits__desc {
  padding: 4px;

  font-size: 14px;
}

.benefits__label {
  margin-bottom: 12px;
}
