:root {
  // === fonts ===
  --font-f__main: 'Nunito', sans-serif;
  // --font-f__h: 'Source Serif Pro', serif;
  // --font-f__quote: 'Caveat', cursive;
  --font-s__main: 24px;

  // === colors ===
  --color__main: #1d2a3b;
  --color__secondary: #495c77;
  --color__w: #fff;
  --color__accent: rgb(104, 74, 184);
  --color__hover: rgba(104, 74, 184, 0.75);

  // === colors bg ===
  --color-bg__main: #f0faff;
  --color-bg__header: rgb(0, 0, 0, 0.7);

  // === shadow ===
  --shadow__main: 0 0 4px rgb(0 0 0 / 20%);

  // card set
  --items: 1;
  --gap: 24px;
  --width: 100%;

  // transition
  --transition__main: 1000ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition__hover: 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

// === screen sizes ===
$small: 480px;
$medium: 768px;
$large: 1200px;

$extraSmallMax: $small - 1;
$smallMax: $medium - 1;
$mediumMax: $large - 1;

// === sizes ===
$height-header: 44px;
$padding-small: 16px;
$padding-medium: calc($padding-small * 2);
$border-radius: 12px;


// #b3a4ff
// burlywood
// #684ab8
// #64afd7
// rgb(108, 143, 189)
// rgb(109, 128, 220)
// rgb(213, 227, 255)
