.sm__list {
  display: flex;
  gap: 12px;
}

.sm__link {
  display: inline-flex;
  align-items: center;
  padding: 4px;

  @extend %border-radius;
  transition: background-color var(--transition__hover);

  &:hover,
  &:focus {
    &.instagram {
      background-color: #c13584;
    }

    &.facebook {
      background-color: #0165e1;
    }
  }
}

.sm__icon {
  width: 24px;
  height: 24px;

  // @extend %currentColor;
  fill: rgba($color: white, $alpha: 0.1);
}
