.btn {
  @extend %flex-center-ja;

  font-size: inherit;
  color: white;

  background-color: black;
  @extend %border-radius;
  @extend %cursor;
  
  &:hover,
  &:focus {
    background-color: var(--color__hover);
  }

  &.current {
    background-color: var(--color__accent);
  }
}

.btn-up {
  @extend .btn;

  position: fixed;
  bottom: $padding-medium;
  right: $padding-medium;

  width: $padding-small * 3;
  height: $padding-small * 3;

  background-color: rgba($color: black, $alpha: 0.6);
  border: 1px solid rgba($color: white, $alpha: 0.4);
  border-radius: 50%;
  // transition: opacity var(--transition__main);
  
  &.isHidden {
    opacity: 0;
    pointer-events: none;
  }
}

.btn-up__icon {
  @extend %currentColor;

  width: 36px;
  height: 36px;
}