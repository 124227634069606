.nav {
  .header & {
    margin-left: auto;

    @include breakpoint-max($smallMax) {display: none;}
  }

  .mobile-menu & {
    padding-bottom: 28px;
  }
}

.nav__list {
  .header & {
    @extend %flex-center-a;
    gap: 16px;
    height: $height-header;
    margin-left: auto;
  }

  .mobile-menu & {
    @extend %flex-dir-column;
    gap: 16px;
  }
}

.nav__link {
  font-size: 20px;
  font-weight: 600;
  @extend %border-radius;

  &:hover,
  &:focus {
    background-color: var(--color__accent);
    outline: none;
  }

  .header & {
    padding: 4px 8px;

    line-height: 1.2;
  }

  .mobile-menu & {
    @extend %flex-center-a;
    max-width: 200px;
    padding: 12px;

    border: 1px solid white;

    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }
}