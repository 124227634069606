.slick-slider {
  max-width: 100%;
  min-width: 0px;
}

.slick-slider.projects-slider {
  position: relative;
}

.slick-list {
  overflow: hidden;
  max-width: 100%;
}

.slick-track {
  display: flex;
}

// dots - точки
.slick-dots {
  @extend %flex-center-ja;
  gap: 4px;
}

.slick-dots button {
  width: 10px;
  height: 10px;

  font-size: 0px;

  background-color: #fff;
  border-radius: 50%;
}

.slick-dots li.slick-active button {
  background-color: transparent;
  border: 1px solid #fff;
}

//  стрілки
.slick-arrow {
  @extend %absolute;
  top: 88%;

  font-size: 0px;
  color: #fff;
  background-color: transparent;

  &.slick-prev {
    transform: rotate(-90deg);
    left: 0;
  }

  &.slick-next {
    transform: rotate(90deg);
    right: 0;
  }
}

.slick-arrow__icon {
  @extend %currentColor;
}

.projects-slider__item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.projects-slider__img {
  width: 100%;

  aspect-ratio: 2/3;
  object-fit: cover;
  @extend %border-radius;
}