// * {
//   outline: 1px solid teal;
// };

html {
  scroll-behavior: smooth;
}

body {
  position: relative;

  font-family: var(--font-f__main);
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: var(--color__main);
}

body.isLoading,
body.modalIsOpen,
body.mobileMenuIsOpen {
  overflow: hidden;
}

iframe,
img {
  display: block;
  max-width: 100%;
  height: auto;
}

button[disabled] {
  cursor: auto;
}

svg {
  display: block;
}

.section-wrap {
  padding-top: calc($height-header + 12px);
}

.section {
  padding-bottom: 32px;

  &.top {
    padding-top: calc($height-header + 32px);
  }
}

.container {
  width: 100%;
  padding: 0 $padding-small;
  margin: 0 auto;

  @include breakpoint-min($small) {
    width: $small;
  }

  @include breakpoint-min($medium) {
    width: $medium;
    padding: 0 $padding-medium;
  }

  @include breakpoint-min($large) {
    width: $large;
  }
}

.title {
  font-size: 28px;
  text-align: center;
  line-height: 1;
}

.subtitle {
  font-size: 20px;
  line-height: 1;
}
