:root {
  --font-f__main: "Nunito", sans-serif;
  --font-s__main: 24px;
  --color__main: #1d2a3b;
  --color__secondary: #495c77;
  --color__w: #fff;
  --color__accent: #684ab8;
  --color__hover: #684ab8bf;
  --color-bg__main: #f0faff;
  --color-bg__header: #000000b3;
  --shadow__main: 0 0 4px #0003;
  --items: 1;
  --gap: 24px;
  --width: 100%;
  --transition__main: 1s cubic-bezier(.4, 0, .2, 1);
  --transition__hover: .4s cubic-bezier(.4, 0, .2, 1);
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

:focus, :active, a:focus, a:hover {
  outline: none;
}

nav, footer, header {
  display: block;
}

a, button, input, textarea {
  color: inherit;
}

a {
  text-decoration: none;
}

ul, li {
  list-style: none;
}

.benefits__list, .projects__list {
  justify-content: center;
  gap: var(--gap);
  flex-wrap: wrap;
  display: flex;
}

.benefits__item, .projects__item {
  flex-basis: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
}

@media (min-width: 768px) {
  .benefits__item, .projects__item {
    --items: 2;
  }
}

@media (min-width: 1200px) {
  .benefits__item, .projects__item {
    --items: 3;
  }
}

.benefits__item, .project__desc, .footer__bottom, .footer__logo-wrap, .header .container, .modal__close-btn, .orline, .contacts__item, .mobile-menu .nav__link, .header .nav__list, .header__logo, .pagination, .mobile-menu__btn, .projects__filter-list, .slick-dots, .backdrop, .btn, .paginationBtn, .projects__filter-btn, .btn-up {
  align-items: center;
  display: flex;
}

.pagination, .mobile-menu__btn, .projects__filter-list, .slick-dots, .backdrop, .btn, .paginationBtn, .projects__filter-btn, .btn-up {
  justify-content: center;
}

.mobile-menu, .projects__item, .footer__top, .footer__container, .contacts__list, .mobile-menu .nav__list {
  flex-direction: column;
  display: flex;
}

.mobile-menu__btn, .project__details-btn, .projects__img-cover, .modal__close-btn, .btn, .paginationBtn, .projects__filter-btn, .btn-up {
  cursor: pointer;
}

.burger-line, .benefits__img, .benefits__item, .projects__item, .projects-slider__img, .hero__desc .accent, .modal__close-btn .burger-line, .sm__link, .contacts__link, .nav__link, .btn, .paginationBtn, .projects__filter-btn, .btn-up {
  border-radius: 12px;
}

.burger-line, .project__details-btn, .slick-arrow, .loader:after, .modal__close-btn .burger-line:first-child, .modal__close-btn, .header__logo:before, .visually-hidden {
  position: absolute;
}

.projects__play-svg, .slick-arrow__icon, .contacts__icon, .btn-up__icon, .logo__icon, .logo__text {
  fill: currentColor;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-f__main);
  letter-spacing: .03em;
  color: var(--color__main);
  font-size: 16px;
  line-height: 1.2;
  position: relative;
}

body.isLoading, body.modalIsOpen, body.mobileMenuIsOpen {
  overflow: hidden;
}

iframe, img {
  max-width: 100%;
  height: auto;
  display: block;
}

button[disabled] {
  cursor: auto;
}

svg {
  display: block;
}

.section-wrap {
  padding-top: 56px;
}

.section {
  padding-bottom: 32px;
}

.section.top {
  padding-top: 76px;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    padding: 0 32px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.title {
  text-align: center;
  font-size: 28px;
  line-height: 1;
}

.subtitle {
  font-size: 20px;
  line-height: 1;
}

.header__logo {
  height: 44px;
  gap: 4px;
  font-weight: 700;
  position: relative;
}

.header__logo:before {
  content: "casting agency";
  opacity: 1;
  transition: opacity var(--transition__main);
  display: block;
  bottom: -10px;
  right: 0;
}

.header.isScrolling .header__logo:before {
  opacity: 0;
}

.logo__text {
  font-size: 24px;
}

.btn, .paginationBtn, .projects__filter-btn, .btn-up {
  font-size: inherit;
  color: #fff;
  background-color: #000;
}

.btn:hover, .paginationBtn:hover, .projects__filter-btn:hover, .btn-up:hover, .btn:focus, .paginationBtn:focus, .projects__filter-btn:focus, .btn-up:focus {
  background-color: var(--color__hover);
}

.btn.current, .current.paginationBtn, .current.projects__filter-btn, .current.btn-up {
  background-color: var(--color__accent);
}

.btn-up {
  width: 48px;
  height: 48px;
  background-color: #0009;
  border: 1px solid #fff6;
  border-radius: 50%;
  position: fixed;
  bottom: 32px;
  right: 32px;
}

.btn-up.isHidden {
  opacity: 0;
  pointer-events: none;
}

.btn-up__icon {
  width: 36px;
  height: 36px;
}

.header .nav {
  margin-left: auto;
}

@media (max-width: 767px) {
  .header .nav {
    display: none;
  }
}

.mobile-menu .nav {
  padding-bottom: 28px;
}

.header .nav__list {
  height: 44px;
  gap: 16px;
  margin-left: auto;
}

.mobile-menu .nav__list {
  gap: 16px;
}

.nav__link {
  font-size: 20px;
  font-weight: 600;
}

.nav__link:hover, .nav__link:focus {
  background-color: var(--color__accent);
  outline: none;
}

.header .nav__link {
  padding: 4px 8px;
  line-height: 1.2;
}

.mobile-menu .nav__link {
  max-width: 200px;
  border: 1px solid #fff;
  padding: 12px;
}

.mobile-menu .nav__link:hover, .mobile-menu .nav__link:focus {
  border: 1px solid #0000;
}

.contacts {
  max-width: 220px;
  flex-direction: column;
  display: inline-flex;
}

.mobile-menu .contacts {
  border-top: 2px solid #ffffff4d;
  border-bottom: 2px solid #ffffff4d;
  padding: 12px 0;
}

.contacts__cta {
  margin-bottom: 8px;
}

.contacts__list {
  gap: 12px;
}

.contacts__list--message {
  flex-flow: wrap;
  justify-content: space-evenly;
  column-gap: 32px;
}

.contacts__link {
  transition: background-color var(--transition__hover);
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
}

.contacts__link:hover, .contacts__link:focus {
  background-color: var(--color__accent);
}

.contacts__link:hover.telegram, .contacts__link:focus.telegram {
  background-color: #08c;
}

.contacts__link:hover.viber, .contacts__link:focus.viber {
  background-color: #59267c;
}

.contacts__link:hover.whatsapp, .contacts__link:focus.whatsapp {
  background-color: #25d366;
}

.contacts__icon {
  width: 20px;
  height: 20px;
}

.contacts__list--message .contacts__icon--message {
  width: 32px;
  height: 32px;
}

.orline {
  gap: 4px;
  padding: 12px 0;
  font-size: 12px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #ffffff4d;
  display: block;
}

.sm__list {
  gap: 12px;
  display: flex;
}

.sm__link {
  transition: background-color var(--transition__hover);
  align-items: center;
  padding: 4px;
  display: inline-flex;
}

.sm__link:hover.instagram, .sm__link:focus.instagram {
  background-color: #c13584;
}

.sm__link:hover.facebook, .sm__link:focus.facebook {
  background-color: #0165e1;
}

.sm__icon {
  width: 24px;
  height: 24px;
  fill: #ffffff1a;
}

.backdrop {
  z-index: 3000;
  background-color: #000000e6;
  position: fixed;
  inset: 0;
}

.backdrop.isHidden {
  display: none;
}

.backdrop.mod {
  z-index: 2000;
}

@media (orientation: landscape) {
  .backdrop.mod {
    align-items: flex-start;
    padding-top: 44px;
    overflow: scroll;
  }
}

.modal.container {
  position: relative;
}

.modal__close-btn {
  width: 32px;
  height: 32px;
  background-color: #0000;
  justify-content: center;
  top: 4px;
  right: 16px;
}

@media (min-width: 768px) and (max-width: 800px) {
  .modal__close-btn {
    right: 32px;
  }
}

.modal__close-btn .burger-line {
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin: 0;
}

.modal__close-btn .burger-line:first-child {
  transform: rotate(-45deg);
}

.modal__close-btn .burger-line:last-child {
  transform: rotate(45deg);
}

.loader {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-top: 4px solid #fff;
  border-right: 4px solid #0000;
  border-radius: 50%;
  animation: 1s linear infinite rotation;
  display: inline-block;
}

.loader:after {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-bottom: 4px solid #0000;
  border-left: 4px solid #684ab8;
  border-radius: 50%;
  animation: .5s linear infinite reverse rotation;
  top: 0;
  left: 0;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header {
  width: 100%;
  height: 44px;
  color: #fff;
  transition: background-color var(--transition__main);
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
}

body.mobileMenuIsOpen .header, .header.isScrolling {
  background-color: #000000e6;
}

.footer {
  color: #fff;
  background-color: #000;
  padding-top: 32px;
}

.footer__container, .footer__top {
  gap: 20px;
}

.footer__logo-wrap {
  justify-content: space-between;
}

.footer-box__title {
  color: #fff6;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 1;
}

.footer__bottom {
  border-top: 1px solid #ffffff4d;
  justify-content: space-between;
  gap: 4px;
  padding: 12px;
}

@media (max-width: 479px) {
  .footer__bottom {
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .footer__bottom {
    grid-column: 1 / 3;
  }
}

.powered {
  color: #ffffffb3;
}

.copyright {
  color: #ffffff4d;
}

.hero.section {
  background-image: linear-gradient(#212121b3, #212121b3), url("bg-img-hero.1f4391d2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media (min-width: 768px) and (min-height: 620px) {
  .hero.section {
    width: 100vw;
    height: 100vh;
  }
}

.hero__container {
  justify-content: space-between;
  gap: 32px;
  display: flex;
}

@media (max-width: 767px) {
  .hero__container {
    flex-direction: column;
    gap: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .hero__box {
    width: 336px;
  }
}

@media (min-width: 1200px) {
  .hero__box {
    width: 602px;
  }
}

.hero__box.slider {
  max-width: 100%;
  min-width: 0;
}

@media (min-width: 1200px) {
  .hero__box.slider {
    width: 368px;
  }
}

.hero__title, .hero__subtitle, .hero__desc {
  color: var(--color__w);
  text-align: center;
}

.hero__title {
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .hero__title {
    margin-bottom: 24px;
  }
}

@media (min-width: 1200px) {
  .hero__title {
    font-size: 48px;
  }
}

.hero__subtitle {
  font-size: 24px;
}

@media (min-width: 1200px) {
  .hero__subtitle {
    font-size: 32px;
  }
}

.hero__desc {
  max-width: 604px;
  text-align: start;
  line-height: 1.5;
}

.hero__desc:not(:last-child) {
  margin-top: 16px;
}

@media (min-width: 1200px) {
  .hero__desc {
    max-width: 880px;
    font-size: 20px;
  }
}

@media (min-width: 1600px) {
  .hero__desc {
    font-size: 24px;
  }
}

.hero__desc .accent {
  color: #000;
  background-color: #fff;
  padding: 0 12px;
}

.slick-slider {
  max-width: 100%;
  min-width: 0;
}

.slick-slider.projects-slider {
  position: relative;
}

.slick-list {
  max-width: 100%;
  overflow: hidden;
}

.slick-track {
  display: flex;
}

.slick-dots {
  gap: 4px;
}

.slick-dots button {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 0;
}

.slick-dots li.slick-active button {
  background-color: #0000;
  border: 1px solid #fff;
}

.slick-arrow {
  color: #fff;
  background-color: #0000;
  font-size: 0;
  top: 88%;
}

.slick-arrow.slick-prev {
  left: 0;
  transform: rotate(-90deg);
}

.slick-arrow.slick-next {
  right: 0;
  transform: rotate(90deg);
}

.projects-slider__item {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.projects-slider__img {
  width: 100%;
  aspect-ratio: 2 / 3;
  object-fit: cover;
}

.projects__slider {
  min-width: 0;
}

.projects__filter-list {
  flex-wrap: wrap;
  gap: 8px;
  margin: 24px 0;
}

.projects__filter-btn {
  width: 88px;
  height: 42px;
}

.projects__item {
  background: #000;
  transition: scale .5s cubic-bezier(.4, 0, .2, 1);
  position: relative;
  overflow: hidden;
  scale: 1;
}

.projects__item:hover, .projects__item:focus {
  scale: 1.05;
}

@media (max-width: 767px) {
  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+2) {
    --animate-duration: 1.25s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+3) {
    --animate-duration: 1.5s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+4) {
    --animate-duration: 1.75s;
  }
}

@media (max-width: 1199px) {
  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+3) {
    --animate-duration: 1.25s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+5) {
    --animate-duration: 1.5s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+7) {
    --animate-duration: 1.75s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+9) {
    --animate-duration: 2s;
  }
}

@media (min-width: 1200px) {
  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+4) {
    --animate-duration: 1.25s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+7) {
    --animate-duration: 1.5s;
  }

  .projects__item.animate__animated.animate__fadeInUp:nth-of-type(n+10) {
    --animate-duration: 2s;
  }
}

.project__desc {
  color: #fff;
  background-color: #000;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 4px;
  padding: 4px 12px 12px;
}

@media (min-width: 1200px) {
  .project__desc {
    font-size: 18px;
  }
}

.modal__body .project__desc {
  padding: 12px 20px;
}

.project__title, .project__category {
  flex-basis: calc(100% - 44px);
}

.project__year {
  width: 40px;
}

.projects__play-svg {
  color: red;
}

.projects__img-cover {
  width: 100%;
  height: 50vw;
  object-fit: cover;
}

@media (min-width: 480px) {
  .projects__img-cover {
    height: 252px;
  }
}

@media (min-width: 768px) {
  .projects__img-cover {
    height: 192px;
  }
}

@media (min-width: 1200px) {
  .projects__img-cover {
    height: 204px;
  }
}

.projects__play-iframe {
  width: 400px;
  height: 225px;
}

@media (min-width: 768px) {
  .projects__play-iframe {
    width: 720px;
    height: 405px;
  }
}

@media (min-width: 1200px) {
  .projects__play-iframe {
    width: 960px;
    height: 840px;
  }
}

.project__details-btn {
  background-color: #0000;
  inset: 0;
}

.projects__iframe {
  width: 100%;
  min-height: 336px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

@media (min-width: 480px) {
  .projects__iframe {
    height: 336px;
  }
}

@media (min-width: 768px) {
  .projects__iframe {
    height: 528px;
  }
}

@media (min-width: 1200px) {
  .projects__iframe {
    height: 852px;
  }
}

@media (orientation: landscape) {
  .projects__iframe {
    max-height: 80vh;
  }
}

#benefits {
  background-color: var(--color__accent);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.benefits__title {
  color: #fff;
  margin-bottom: 24px;
}

.benefits__list {
  gap: 24px;
}

@media (min-width: 768px) {
  .benefits__list {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media (min-width: 1200px) {
  .benefits__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.benefits__item {
  background-color: #fff;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .benefits__item:nth-child(2) {
    grid-column: 1 / 3;
  }

  .benefits__item:nth-child(1), .benefits__item:nth-child(3) {
    order: -1;
  }
}

@media (min-width: 1200px) {
  .benefits__item:nth-child(2) {
    grid-column: 2 / 4;
  }
}

.benefits__img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .benefits__img {
    width: 100%;
  }
}

.benefits__desc {
  padding: 4px;
  font-size: 14px;
}

.benefits__label {
  margin-bottom: 12px;
}

.mobile-menu__btn {
  width: 32px;
  height: 44px;
  background: none;
  margin-left: auto;
  position: relative;
}

.mobile-menu__btn.isOpen {
  justify-content: flex-start;
}

.header.isScrolling .mobile-menu__btn {
  height: 40px;
}

@media (min-width: 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.burger-line {
  width: 100%;
  height: 3px;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

.mobile-menu__btn.isOpen .burger-line:first-child {
  transform: rotate(-45deg);
}

.mobile-menu__btn.isOpen .burger-line:nth-child(2n) {
  opacity: 0;
}

.mobile-menu__btn.isOpen .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  color: #fff;
  z-index: 1001;
  visibility: hidden;
  pointer-events: none;
  transition: transform var(--transition__main), visibility var(--transition__main);
  background-color: #000000e6;
  padding: 28px 16px;
  position: fixed;
  inset: 44px 0 0;
  overflow-y: scroll;
  transform: translateX(100%);
}

body.isScrolling .mobile-menu {
  top: 44px;
}

.mobile-menu.isOpen {
  visibility: visible;
  pointer-events: all;
  transform: translateX(0);
}

@media (min-width: 768px) {
  .mobile-menu {
    left: 50%;
  }
}

@media (min-width: 1200px) {
  .mobile-menu {
    display: none;
  }
}

.pagination {
  z-index: 1;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
}

.paginationBtn {
  width: 40px;
  height: 35px;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=index.48aa18df.css.map */
