.header {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: $height-header;

  color: white;
  transition: background-color var(--transition__main);
  z-index: 2000;

  body.mobileMenuIsOpen &,
  &.isScrolling {
    background-color: rgba($color: #000000, $alpha: 0.9);
  }
}

.header .container {
  @extend %flex-center-a;

  // gap: 24px;
  // justify-content: space-between;
}
