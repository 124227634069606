.projects__slider {
  min-width: 0;
}

.projects__filter-list {
  @extend %flex-center-ja;
  flex-wrap: wrap;
  gap: 8px;

  margin: 24px 0;
}

.projects__filter-btn {
  @extend .btn;
  width: 88px;
  height: 42px;
}

.projects__list {
  @extend %cardSet;
}

.projects__item {
  position: relative;

  @extend %cardSet__item;
  @extend %flex-dir-column;

  @extend %border-radius;
  background: #000;
  overflow: hidden;
  transition: scale 500ms cubic-bezier(0.4, 0, 0.2, 1);
  scale: 1;

  &:hover,
  &:focus {
    scale: 1.05;
  }
}

.projects__item.animate__animated.animate__fadeInUp {
  @include breakpoint-max($smallMax) {
    &:nth-of-type(n + 2) {
      --animate-duration: 1250ms;
    }
    &:nth-of-type(n + 3) {
      --animate-duration: 1500ms;
    }
    &:nth-of-type(n + 4) {
      --animate-duration: 1750ms;
    }
  }
  @include breakpoint-max($mediumMax) {
    &:nth-of-type(n + 3) {
      --animate-duration: 1250ms;
    }
    &:nth-of-type(n + 5) {
      --animate-duration: 1500ms;
    }
    &:nth-of-type(n + 7) {
      --animate-duration: 1750ms;
    }
    &:nth-of-type(n + 9) {
      --animate-duration: 2000ms;
    }
  }
  @include breakpoint-min($large) {
    &:nth-of-type(n + 4) {
      --animate-duration: 1250ms;
    }
    &:nth-of-type(n + 7) {
      --animate-duration: 1500ms;
    }
    &:nth-of-type(n + 10) {
      --animate-duration: 2000ms;
    }
  }
}

.project__desc {
  @extend %flex-center-a;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 4px;
  padding: 4px 12px 12px;

  color: white;

  background-color: black;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  @include breakpoint-min($large) {
    font-size: 18px;
  }
}

.project__desc  {
  .modal__body & {
    padding: 12px 20px;
  }
}

.project__title,.project__category {
  flex-basis: calc(100% - 44px);
}
.project__year {
  width: 40px;
}

.projects__play-svg {
  color: #ff0000;
  @extend %currentColor;
}

.projects__img-cover {
  width: 100%;
  height: calc(50vw);
  object-fit: cover;
  
  @extend %cursor;

  @include breakpoint-min($small) {
    height: 252px;
  }
  @include breakpoint-min($medium) {
    height: 192px;
  }
  @include breakpoint-min($large) {
    height: 204px;
  }
}

.projects__play-iframe {
  width: 400px;
  height: 225px;

  @include breakpoint-min($medium) {
    width: 720px;
    height: 405px;
  }
  @include breakpoint-min($large) {
    width: 960px;
    height: 840px;
  }
}

.project__details-btn {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @extend %cursor;
  background-color: transparent;
}

.projects__iframe {
  width: 100%;
  min-height: 336px;

  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius; 

  @include breakpoint-min($small) {
    height: 336px;
  }
  @include breakpoint-min($medium) {
    height: 528px;
  }
  @include breakpoint-min($large) {
    height: 852px;
  }
  @media (orientation: landscape) {
    max-height: 80vh;
  }
}