.mobile-menu__btn {
  position: relative;

  @extend %flex-center-ja;
  width: 32px;
  height: $height-header;
  margin-left: auto;

  background: none;
  @extend %cursor;

  &.isOpen {
    justify-content: flex-start;
  }

  .header.isScrolling & {
    height: 40px;
  }

  @include breakpoint-min($medium) {
    display: none;
  }
}

.burger-line {
  @extend %absolute;

  width: 100%;
  height: 3px;
  margin: 0;

  background-color: currentColor;
  @extend %border-radius;

  transition-duration: 0.4s;
  transition-property: height, opacity, transform;

  &:first-child {
    transform: translateY(8px);
  }

  &:last-child {
    transform: translateY(-8px);
  }

  .mobile-menu__btn.isOpen & {
    &:first-child {
      transform: rotate(-45deg);
    }

    &:nth-child(2n) {
      opacity: 0;
    }

    &:last-child {
      transform: rotate(45deg);
    }
  }
}

.mobile-menu {
  position: fixed;
  top: $height-header;
  left: 0;
  bottom: 0;
  right: 0;

  @extend %flex-dir-column;
  padding: 28px 16px;

  color: white;

  background-color: rgba($color: black, $alpha: 0.9);
  overflow-y: scroll;
  z-index: 1001;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform var(--transition__main),
    visibility var(--transition__main);

  body.isScrolling & {
    top: 44px;
  }

  &.isOpen {
    transform: translateX(0);
    visibility: visible;
    pointer-events: all;
  }

  @include breakpoint-min($medium) {
    left: 50%;
  }
  @include breakpoint-min($large) {
    display: none;
  }
}
