.footer {
  padding-top: 32px;

  color: white;
  background-color: black;
}

.footer__container {
  @extend %flex-dir-column;
  gap: 20px;
}

.footer__top {
  @extend %flex-dir-column;
  gap: 20px;
}

.footer__logo-wrap {
  @extend %flex-center-a;
  justify-content: space-between;
}

.footer-box__title {
  margin-bottom: 16px;

  color: rgba($color: white, $alpha: 0.4);
  font-size: 20px;
  line-height: 1;
}

.footer__bottom {
  @extend %flex-center-a;
  justify-content: space-between;
  gap: 4px;
  padding: 12px;

  border-top: 1px solid rgba($color: #fff, $alpha: 0.3);

  @include breakpoint-max($extraSmallMax) {
    justify-content: center;
    flex-direction: column;
  }
  @include breakpoint-min($large) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.powered {
  color: rgba($color: #fff, $alpha: 0.7);
}

.copyright {
  color: rgba($color: #fff, $alpha: 0.3);
}
