.contacts {
  display: inline-flex;
  flex-direction: column;
  max-width: 220px;
  
  .mobile-menu & {
    padding: 12px 0;
    border-top: 2px solid rgba($color: #fff, $alpha: 0.3);
    border-bottom: 2px solid rgba($color: #fff, $alpha: 0.3);
  }
}

.contacts__cta {
  margin-bottom: 8px;
}

.contacts__list {
  @extend %flex-dir-column;
  gap: 12px;

  &--message {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    column-gap: 32px;
  }
}

.contacts__item {
  @extend %flex-center-a;
}

.contacts__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;

  @extend %border-radius;
  transition: background-color var(--transition__hover);

  &:hover,
  &:focus {
    background-color: var(--color__accent);

    &.telegram {
      background-color: #0088cc;
    }

    &.viber {
      background-color: #59267c;
    }

    &.whatsapp {
      background-color: #25d366;
    }
  }
}

.contacts__icon {
  @extend %currentColor;

  width: 20px;
  height: 20px;
  
  .contacts__list--message &--message {
    width: 32px;
    height: 32px;
  }
}

.orline {
  @extend %flex-center-a;
  gap: 4px;
  padding: 12px 0;

  font-size: 12px;
}

.line {
  display: block;
  width: 100%;
  height: 2px;

  background-color: rgba($color: #fff, $alpha: 0.3);
}
