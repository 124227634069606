@import '../../common/scss/base';
@import '../../layouts/header/header';
@import '../../layouts/footer/footer';

@import '../../pages/home/hero/hero';
@import '../../layouts/sections/projects/projects-slider';
@import '../../layouts/sections/projects/projects';
@import '../../layouts/sections/benefits/benefits';

@import '../../components/mobile-menu/mobile-menu';
@import '../../components/paginations/pagination';