*,
*::before,
*::after {
  box-sizing: border-box;
};

* {
  margin: 0;
  padding: 0;
  border: 0;
};

:focus,
:active,
a:focus,
a:hover {
  outline: none;
};

nav,
footer,
header {
  display: block;
};

a,
button,
input,
textarea {
  color: inherit;
};

a {
  text-decoration: none;
};

ul,
li {
  list-style: none;
};