.header__logo {
  position: relative;
  @extend %flex-center-a;
  height: $height-header;
  gap: 4px;

  font-weight: 700;

  &::before {
    content: 'casting agency';
    @extend %absolute;
    right: 0;
    bottom: -10px;
    display: block;

    opacity: 1;
    transition: opacity var(--transition__main);

    .header.isScrolling & {
      opacity: 0;
    }
  }
}

.logo__icon,
.logo__text {
  @extend %currentColor;
}

.logo__text {
  font-size: 24px;
}
