.hero.section {
  background-image: linear-gradient(
      rgba(33, 33, 33, 0.7),
      rgba(33, 33, 33, 0.7)
    ),
    url(../../../static/images/hero/bg-img-hero.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  @media (min-width: $medium) and (min-height: 620px) {
    width: 100vw;
    height: 100vh;
  }
}

.hero__container {
  display: flex;
  justify-content: space-between;
  gap: $padding-medium;

  @include breakpoint-max($smallMax) {
    flex-direction: column;
    gap: $padding-small;
  }
}

.hero__box {
  @include breakpoint-min-max($medium, $mediumMax) {
    width: calc(($medium - 3 * $padding-medium) / 2);
  }

  @include breakpoint-min($large) {
    // width: calc(($large - 3 * $padding-medium) / 3 * 2 + 40px);
    width: calc(($large - 3 * $padding-medium) / 2 + 50px);
  }
}

.hero__box.slider {
  max-width: 100%;
  min-width: 0;

  @include breakpoint-min($large) {
    // width: calc(($large - 3 * $padding-medium) / 3 - 40px);
    width: calc(($large - 3 * $padding-medium) / 3);
  }

  // &.animate__animated.animate__fadeIn {
  // --animate-duration: 2s;
  // --animate-delay: 2s;
  // --animate-repeat: 3;
  // }
}

.hero__title,
.hero__subtitle,
.hero__desc {
  color: var(--color__w);
  text-align: center;
}

.hero__title {
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 32px;

  @include breakpoint-min($medium) {
    margin-bottom: 24px;
  }
  @include breakpoint-min($large) {
    font-size: 48px;
  }
}

.hero__subtitle {
  font-size: 24px;

  @include breakpoint-min($large) {
    font-size: 32px;
  }
}

.hero__desc {
  max-width: 604px;

  &:not(:last-child) {
    margin-top: 16px;
  }

  text-align: start;
  line-height: 1.5;
  
  @include breakpoint-min($large) {
    max-width: 880px;

    font-size: 20px;
  }

  @include breakpoint-min(1600px) {
    font-size: 24px;
  }
}

.hero__desc .accent {
  padding: 0 12px;

  color: black;

  @extend %border-radius;
  background-color: rgba($color: white, $alpha: 1);
}
